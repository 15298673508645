export function getUrlEndpoint(url){
    const arr = url.split('/');
    return arr[arr.length-1]
}

export const getPositions=()=>{
    const home = document.getElementById("home").offsetHeight;
    const about = document.getElementById("aboutSection").offsetHeight;
    const exp = document.getElementById("expSection").offsetHeight;
    const porjects = document.getElementById("projects").offsetHeight;
    const contact = document.getElementById("contact").offsetHeight;

    const currPos = {
        home:0,
        about: home,
        exp: Number(home)+ Number(about),
        pro: Number(home)+ Number(about)+ Number(exp),
        con: Number(home)+ Number(about)+ Number(exp)+ Number(porjects),
    }
    return currPos
  }
export const getSection=(pos)=>{
    const positions= getPositions()
    // console.log(pos)
    if(pos>=positions.home && pos<positions.about){
      return "#home";
    }
    else if(pos>=positions.about && pos<positions.exp){
      return "#aboutSection";
    }
    else if(pos>=positions.exp && pos<=positions.pro){
      return "#expSection";
    }
    else if(pos>=positions.pro && pos<=positions.con){
      return "#projects";
    }
    else{
      return "#contact"
    }
  }