import React from "react";
import "./About.css";
import me from "../Images/me.jpeg";
import { motion } from "framer-motion";
import { Icon } from '@iconify/react';

export default function About() {
  return (
    <div
     style={{ display: "grid", placeItems: "center"}}>
        <motion.h1 
        initial={{ opacity: 0,x:0}}
        whileInView={{ opacity: 1,x:50 }}
        transition={{duration:2}}
        id="about-head">About Me</motion.h1>
      <div id="about">
        <div id="about-left">
          <img src={me} alt="profile-pic" />
        </div>
        <div id="about-right">
          <p>
            {" "}
            Hello there! I'm Aniket, a self-taught software developer with expertise in building full-stack web applications with amazing designs.
            I am a creative builder with a knack for building innovative projects.
          </p>
          <p>Here are a few technologies I’ve been working with recently:</p>
          
          <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start", marginTop:"40px",flexWrap:"wrap"}}>
            <span title="Javascript" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="logos:javascript" /></span>
            <span title="React" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="devicon:react" /></span>
            <span title="Node" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="vscode-icons:file-type-node" /></span>
            <span title="TypeScript" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="logos:typescript-icon" /></span>
            <span title="Dotnet" id="skillIcon"><Icon icon="logos:dotnet" style={{fontSize:"40px"}} /></span>
            <span title="Angular" id="skillIcon"><Icon icon="vscode-icons:file-type-angular" style={{fontSize:"40px"}} /></span>
            <span title="NextJs" id="skillIcon"><Icon icon="devicon:nextjs" style={{fontSize:"40px"}} /></span>

            <span title="Firebase" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="vscode-icons:file-type-firebase" /></span>
            <span title="Git" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="devicon:git" /></span>
            <span title="MongoDb" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="skill-icons:mongodb" /></span>
            <span title="MaterialUi" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="devicon:materialui" /></span>
            <span title="MySql" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="logos:mysql" /></span>
            <span title="PHP" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="vscode-icons:file-type-php" /></span>
            <span title="Laravel" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="logos:laravel" /></span>
            <span title="AWS" id="skillIcon"><Icon style={{fontSize:"40px"}} icon="logos:aws" /></span>
            </div>
        </div>
      </div>
    </div>
  );
}
