import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
  ZoomableGroup
} from "react-simple-maps";

const Map = () => {
  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-10.0, 10, -5],
        center: [30, 5],
        scale: 150
      }}
      style={{width: "100%", height: "550px",marginLeft:"100px"}}
    >
      <Geographies
        geography="/features.json"
        fill="rgb(164, 221, 210)"
        stroke="rgb(19, 155, 128)"
        strokeWidth={0.5}
      >
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
      <Annotation
        subject={[80.3522, 22.8566]}
        dx={90}
        dy={-30}
        connectorProps={{
          stroke: "#FF5533",
          strokeWidth: 3,
          strokeLinecap: "round"
        }}
      >
        <text x="38" textAnchor="end" alignmentBaseline="middle" fill="#F53">
          {"India"}
        </text>
      </Annotation>
    </ComposableMap>
  );
};

export default Map;
