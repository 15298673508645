import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footer'>
        <span>&copy; <span id='footName'>Aniket Sinha</span></span>
    </div>
  )
}
