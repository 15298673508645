import React, { useEffect, useState } from "react";
import "./Header.css";
import as from "../Images/as-logo.png";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { getUrlEndpoint } from "../../helpers/helpers";

export default function Header({selected}) {
  return (
    <div className="header">
      <h2>
        <img id="logo" src={as}></img>
      </h2>

      <div id="navbar">
        <div className={selected=="#home"? "selected":"unselected"}>
        <a href="#home" className="navItem">       
          Home
        </a>
        </div>
        <div className={selected=="#aboutSection"? "selected":"unselected"}>
        <a
          href="#aboutSection"
          className="navItem"
        >
          About
        </a>
        </div>
        <div className={selected=="#expSection"? "selected":"unselected"}>
        <a href="#expSection" className="navItem">
          Experience
        </a>
        </div>
        <div className={selected=="#projects"? "selected":"unselected"}>
        <a
        className="navItem"
          href="#projects"
        >
          Projects
        </a>
        </div>
        <div className={selected=="#contact"? "selected":"unselected"}>
        <a href="#contact" className="navItem" >
          Contact
        </a>
        </div>
      </div>
      <div className="header_right">
        {/* <a
          href="https://drive.google.com/file/d/1x9P-YRZUX3O5d9DNdeA3mpRG1qRT3M8z/view?usp=sharing"
          target="_blank"
        >
          <motion.button
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            id="resume"
          >
            <Icon icon="pajamas:doc-text" />
          </motion.button>
        </a> */}
        <a
          href="https://x.com/aniket47274"
          target="_blank"
        >
          <motion.button
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            id="resume"
          >
            <Icon icon="pajamas:twitter" />
          </motion.button>
        </a>
        <a
          href="https://www.linkedin.com/in/aniket-sinha-a2ab29241/"
          target="_blank"
        >
          <motion.button
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            id="resume"
          >
            <Icon icon="bi:linkedin" />
          </motion.button>
        </a>
        <a href="https://github.com/aniketsinha5552" target="_blank">
          <motion.button
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            id="resume"
          >
            <Icon icon="bi:github" />
          </motion.button>
        </a>
      </div>
    </div>
  );
}
