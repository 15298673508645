import React from 'react'
import './Sidebar.css'

export default function Sidebar() {
  return (
    <div className='sidebar'>
        <p>
          aniketsinha5552@gmail.com    ----------------------------</p>
        </div>
  )
}
