import React, { useRef } from "react";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import "./Contact.css";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import Map from "./Map";

export default function Contact() {
  const form = useRef();
  const [success, setSuccess] = React.useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_9za8rfw",
        "template_w8vskmo",
        form.current,
        "rbuf5hESm8nnWVvdC"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccess(true);
        },
        (error) => {
          console.log(error.text);
          setSuccess(false);
        }
      );
  };
  return (
    <div className="contact">
      <div id="contactLeft">
        <motion.h2
          id="contactTitle"
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            boxShadow: "10px 10px 0 rgb(0,0,0,0.5)",
            scale: 1.1,
          }}
          transition={{ duration: 1 }}
        >
          Get In Touch!
        </motion.h2>
        <form id="contactForm" ref={form} onSubmit={sendEmail}>
          <input type="text" placeholder="Name" name="name" />
          <input type="text" placeholder="Email" name="email" />
          <textarea
            rows="5"
            cols="60"
            name="message"
            placeholder="Enter Message"
          ></textarea>
          <button id="sendBtn" type="submit">
            Send
          </button>
          {success === true ? "Message Sent !" : null}
        </form>
        <div id="icons">
          <motion.a
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            href="https://github.com/aniketsinha5552"
            target="_blank"
          >
            <GitHubIcon />
          </motion.a>
          <motion.a
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            href="https://www.linkedin.com/in/aniket-sinha-a2ab29241/"
            target="_blank"
          >
            <LinkedInIcon />
          </motion.a>
          <motion.a
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            href="mailto:aniketsinha5552@gmail.com"
            target="_blank"
          >
            <EmailIcon />
          </motion.a>
          {/* <motion.a
            whileHover={{ boxShadow: "5px 5px 0 rgb(0,0,0,0.5)", scale: 1.05 }}
            transition={{ duration: 0.5 }}
            href="https://www.instagram.com/aniket._sinha/"
            target="_blank"
          >
            <InstagramIcon />
          </motion.a> */}
        </div>
      </div>
      <div id="contactRight">
        <Map/>
      {/* <ComposableMap style={{width:"100%",height:"auto",color:"white"}}>
      <Geographies geography="/features.json">
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
    </ComposableMap> */}
      </div>
    </div>
  );
}
