import mdbGif from "../Images/project gifs/mdb-demo.gif";
import zen from "../Images/project gifs/zen.jpg";
import animeStore from "../Images/animeStore.jpg"
import converse from "../Images/converse-ai.jpg"
import news from "../Images/NewsPiece.png"
import gol from "../Images/gol.png"
import zenLogo from "../Images/project_logos/zen.png"
import converseLogo from "../Images/project_logos/converse.png"
import mdbLogo from "../Images/project_logos/mdb.png"
import newsLogo from "../Images/project_logos/news.png"
import golLogo from "../Images/project_logos/gol.png"
import techblog from "../Images/project_logos/techblog.png"
import defaultLogo from "../Images/project_logos/default.png"
import rb from "../Images/researchbuddy.jpg"

export const myProjects = [
  {
    name: "Research Buddy",
    about:
      "Your AI-powered assistant for all your research needs. Converse with your documents and get relevant answers instantly",
    tech: ["NextJs", "Typescript", "Tailwind"," MongoDB", "Prisma", "Supabase", "LangChain", "Open AI embeddings"," AWS S3"],
    github: "https://github.com/aniketsinha5552/researchbuddy",
    website: "https://researchbuddy.pro",
    img: rb,
    logo: rb
  },
  {
    name: "Zen",
    about:
      "Lo-fi and pixel-art themed virtual study environment, equipped with a lofi music player, ai chatbot, to-do list, clock, real-time weather and exciting games. This project was essentially born out of my desire for an all-in-one space for all my study needs throughout university 🎓",
    tech: ["React","Firebase","OpenAI API","Material UI"],
    github: "https://github.com/aniketsinha5552/zen-client",
    website: "https://zen-lofi.netlify.app/",
    img: zen,
    logo: zenLogo
  },
  {
    name: "News Piece",
    about:
      "A full-stack cross platform mobile app which uses web-scraping technique to get all the latest news from the popular news channels with search feature",
    tech: ["React Native", "NodeJs", "ExpressJs", "CheerioJs","RESTful APIs"],
    github: "https://github.com/aniketsinha5552/NewsPiece",
    website: "https://expo.dev/@aniketsinha/NewsPiece",
    img: news,
    logo: newsLogo
  },
  {
    name: "Techblog",
    about:
    "A personal blogging website where I write about all things tech with features such as google authentication, comments,dark theme etc.",
    tech: ["NextJs", "Javascript", "MongDb", "Prisma", "Next-Auth", "Firebase Storage"],
    github: "https://github.com/aniketsinha5552/techblog",
    website: "https://blog.aniketsinha.life/",
    img: techblog,
    logo: techblog
  },
  {
    name: "Game Of Life",
    about:
      "An implementation of Conway’s Game of Life (an example of a cellular automaton) using javascript runnig on an AWS EC2 instance and hosted on a custom domain",
    tech: ["HTML","CSS","Javascript","Webpack","AWS EC2"],
    github: "https://github.com/aniketsinha5552/conways-game-of-life",
    website: "http://gameoflife.aniketsinha.life",
    img: gol,
    logo: golLogo
  },
  {
    name: "Converse.ai",
    about:
    "A personalized AI chat-bot application to chat with different AI models. You can also train your own AI model by providing prompts.Equipped with Clerk Authentication and Stripe Payment",
    tech: ["NextJs", "Typescript", "Tailwind CSS", "Prisma", "MySql", "LangChain", "Open AI"],
    github: "https://github.com/aniketsinha5552/converse.ai",
    website: "https://converse-ai-zeta.vercel.app/sign-in?redirect_url=https%3A%2F%2Fconverse-ai-zeta.vercel.app%2F",
    img: converse,
    logo: converseLogo
  },
  {
    name: "The Anime Store",
    about:
    "A full-stack e-commerce website using MERN stack with all the essential features. Implemented user authentication and authorization using JWT for secure access to account information.",
    tech: ["NodeJs", "MongoDb", "React", "Express", "JWT", "Redux", "Tailwind CSS"],
    github: "https://github.com/aniketsinha5552/The-Anime-Store",
    website: "https://the-anime-store.vercel.app/",
    img: animeStore,
    logo:defaultLogo
  },

  {
    name: "MDB",
    about:
      "A Movies Database website. View list of popular,upcoming and top rated movies which is always getting updated through the TMDB API. Also implemented autocomplete search and watchlist feature",
    tech: ["React", "Axios", "Firebase"],
    github: "https://github.com/aniketsinha5552/MDb",
    website: "https://mdb-movies.netlify.app/",
    img: mdbGif,
    logo: mdbLogo
  },
];
