import { Box } from "@mui/system";
import React from "react";
import "./Experience.css";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import vesta from "../Images/company_logos/vestaLogo.jpg";
import smart from "../Images/company_logos/smartInternz.png";
import lti from "../Images/company_logos/lti.jpeg"

export default function Experience() {
  return (
    <Box className="exp_container">
      <motion.h1
        initial={{ opacity: 0, x: 0 }}
        whileInView={{ opacity: 1, x: 40 }}
        transition={{ duration: 2 }}
        id="about-head"
      >
        My Experiences
      </motion.h1>
      <div className="exp_outer">
        {/* LTIMintree */}
        <motion.div
          id="exp-body"
          whileHover={{ scale: 1.01 }}
          transition={{ duration: 2 }}
        >
          <div id="exp-left">
            <img id="companyLogo" src={lti} alt="company_logo"/>
          </div>
          <div id="exp-right">
            <div id="company">
              <a id="company_name" href="https://www.linkedin.com/company/ltimindtree/" target="_blank">
                LTIMindtree{" "}
              </a>
              <span>( Dec 2023 - present )</span>
            </div>
            <h3>Software Engineer </h3>
            <ul className="exp-desc">
              <li>
                Worked on technologies such as Dotnet, Angular and MS SQL Server to build robust and scalable web applications.
              </li>
              <li>
                As a part of the insurance team, worked on Duck Creek Technologies DCT software to create and manage policies for a US based client
              </li>
            </ul>
            <div className="exp_tech">
              <span title="Dotnet" id="expIcon">
                <Icon style={{ fontSize: "25px" }} icon="logos:dotnet" />
              </span>
              <span title="Angular" id="expIcon">
                <Icon
                  style={{ fontSize: "25px" }}
                  icon="skill-icons:angular-dark"
                />
              </span>
              <span title="TypeScript" id="expIcon">
                <Icon
                  style={{ fontSize: "25px" }}
                  icon="logos:typescript-icon"
                />
              </span>
              <span title="Git" id="expIcon">
                <Icon style={{ fontSize: "25px" }} icon="devicon:git" />
              </span>
              <span title="MS SQL Server" id="expIcon">
                <Icon style={{ fontSize: "25px" }} icon="devicon-plain:microsoftsqlserver-wordmark" />
              </span>
            </div>
          </div>
        </motion.div>

        {/* Vesta */}
        <motion.div
          id="exp-body"
          whileHover={{ scale: 1.01 }}
          transition={{ duration: 2 }}
        >
          <div id="exp-left">
            <img id="companyLogo" src={vesta} alt="company_logo"/>
          </div>
          <div id="exp-right">
            <div id="company">
              <a id="company_name" href="https://www.linkedin.com/company/checkinntech/" target="_blank">
                Vesta{" "}
              </a>
              <span>( Feb 2023 - Dec 23 )</span>
            </div>
            <h3>Full Stack Developer </h3>
            <ul className="exp-desc">
              <li>
                Developed and maintained web applications by building end to end
                features, improving the user experience and app performance
              </li>
              <li>
              Improved the user experience by implementing an end to end voice to text feature in the feedback system using AI tools
              </li>
              <li>
                Spearheaded the new Staff Deployment module in the Vesta app,
                creating backend APIs, integrating third party APIs and also the
                building frontend UI, which led to a major client acquisition
              </li>
              <li>
                Worked on automated reporting system using Laravel, improved the
                efficiency of the reporting infrastructure by optimizing various
                database queries using Eloquent ORM
              </li>
            </ul>
            <div className="exp_tech">
              <span title="React" id="expIcon">
                <Icon style={{ fontSize: "25px" }} icon="devicon:react" />
              </span>
              <span title="TypeScript" id="expIcon">
                <Icon
                  style={{ fontSize: "25px" }}
                  icon="logos:typescript-icon"
                />
              </span>
              <span title="Git" id="expIcon">
                <Icon style={{ fontSize: "25px" }} icon="devicon:git" />
              </span>
              <span title="MaterialUi" id="expIcon">
                <Icon style={{ fontSize: "25px" }} icon="devicon:materialui" />
              </span>
              <span title="MySql" id="expIcon">
                <Icon style={{ fontSize: "25px" }} icon="logos:mysql" />
              </span>
              <span title="PHP" id="expIcon">
                <Icon icon="vscode-icons:file-type-php" />
              </span>
              <span title="Laravel" id="expIcon">
                <Icon icon="devicon:laravel" />
              </span>
            </div>
          </div>
        </motion.div>

        {/* SmartInternz */}
        <motion.div
          id="exp-body"
          whileHover={{ scale: 1.01 }}
          transition={{ duration: 2 }}
        >
          <div id="exp-left">
            <img id="companyLogo" src={smart} alt="company_logo" />
          </div>
          <div id="exp-right">
            <div id="company">
              <a
                id="company_name"
                href="https://drive.google.com/file/d/1SWjC9gnrg3PxdGIpZt0d2XfeePUBMpAZ/view"
                target="_blank"
              >
                SmartInternz{" "}
              </a>
              <span>( March 2022 - May 2022 )</span>
            </div>
            <h3>Data Analytics Intern</h3>
            <ul className="exp-desc">
              <li>
                Used IBM Cognos for creation of models to train data for
                visualization.
              </li>
              <li>
                Learnt about data visualization and table manipulation for
                different datasets.
              </li>
              <li>
                Analysed the world population estimates given by UN and
                visualized by creating a dashboard. <a style={{color:"blueviolet"}} target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1iw3swDSb_huVFmyi4KY3hVhT0eJA3Zom/view?usp=sharing"> (pdf)</a>
              </li>
            </ul>
            <div className="exp_tech">
              <span title="Excel" id="expIcon">
                <Icon icon="vscode-icons:file-type-excel" style={{ fontSize: "25px" }} />
              </span>
              <span title="Data Analysis" id="expIcon">
               <Icon icon="fluent:data-pie-20-filled" style={{ fontSize: "25px" }} />
              </span>
              <span title="IBM Cognos" id="expIcon">
              <Icon icon="cib:ibm" style={{ fontSize: "25px" }}/>
              </span>
            </div>
          </div>
        </motion.div>
      </div>
    </Box>
  );
}
