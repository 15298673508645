import React from 'react'
import styles from "./Projects.module.css"
import { Icon } from '@iconify/react/dist/iconify.js'

const Card = ({project}) => {
  return (
    <div className={styles.card}>
        <div className={styles.cardLeft}>
        <img className={styles.logo} src={project.logo} alt="logo"/>
        </div>
       <div className={styles.cardRight}>
       <h2 className={styles.name}>{project.name}</h2>
       <p className={styles.desc}>{project.about}</p>
       <div className={styles.techs}>
        {project.tech.map(tech=><span className={styles.tech}>{tech}{" "}</span>)}
       </div>
       <div className={styles.btnContainer}>
       {project?.website && <a className={styles.btn} href={project.website} target="_blank" rel="noreferrer">Try It Out <Icon icon="ph:link-light" /></a>}
       {project?.github && <a className={styles.btn} href={project.github} target="_blank" rel="noreferrer">Learn More <Icon icon="prime:github" /></a>}
       </div>
       </div>
    </div>
  )
}

export default Card