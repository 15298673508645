import React, { useState } from "react";
import styles from "./Projects.module.css";
import { motion } from "framer-motion";
import { myProjects } from "./myProjects";
import Card from "./Card";
import { Icon } from "@iconify/react/dist/iconify.js";

const Projects = () => {
  console.log(myProjects);

  const [count, setCount] = useState(4);
  const [viewMore, setViewMore] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.projects}>
        <motion.h2
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          Some Things I've Built.
        </motion.h2>
      </div>
      <div className={styles.projectList}>
        {myProjects.slice(0, count).map((project,idx) => (
          <Card key={idx} project={project} />
        ))}
      </div>
      {viewMore ? (
        <motion.div
          onClick={() => {
            setCount(4); setViewMore(false);
          }}
          whileTap={{ scale: 0.95 }}
          whileHover={{ scale: 1.05 }}
          style={{ alignItems:"center",margin:"2px",padding:"2px",borderRadius: "10%", display:"flex", flexDirection:"row"}}
        >
          View Less <Icon icon="icon-park-outline:double-up" />
        </motion.div>
      ) : (
        <motion.div
          onClick={() => {
            setCount(8); setViewMore(true);
          }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 300 }}
          whileHover={{ scale: 1.05 }}
          style={{ alignItems:"center",margin:"2px",padding:"2px",borderRadius: "10%",cursor:"pointer", display:"flex", flexDirection:"row" }}
        >
          View More <Icon icon="icon-park-outline:double-down" />
        </motion.div>
      )}
    </div>
  );
};

export default Projects;
