import "./App.css";
import Contact from "./Components/Contact";
import Home from "./Components/Home/Home";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer";
import Projects from "./Components/Projects/Projects";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import About from "./Components/About/About";
import Sidebar from "./Components/Sidebar/Sidebar";
import Experience from "./Components/Experience/Experience";
import Lottie from 'react-lottie';
import animationData from "../src/Components/dynamic/loading.json"
import { getSection ,getUrlEndpoint} from "./helpers/helpers";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const sectionStyle = {
    minHeight: "100vh",
    scrollSnapAlign: "center",
    marginTop: "25px",
    paddingTop: "20px",
    position: "relative",
  };

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const [selected,setSelected]= useState("#home");
  const scrollSection=()=>{
    const sec= document.querySelector('.App')
    // console.log(getSection(sec.scrollTop))
    setSelected(getSection(sec.scrollTop))
  }
  return (
    <ChakraProvider>
      
      <motion.div className="App" onScroll={scrollSection}>
        <Header selected={selected}/>
        <Sidebar />
        {isLoading ? 
        <div className="loader" style={{height:"100vh"}}>
         <Lottie options={defaultOptions}
              height={400}
              width={400}
              isStopped={isLoading} />
        </div>
        :<>
        <section id="home" style={sectionStyle}>
          <Home />
          {/* <ScrollDownBtn to="aboutSection" /> */}
        </section>

        <section id="aboutSection" style={{...sectionStyle,backgroundColor:"#002244"}}>
          <About />
          {/* <ScrollDownBtn to="expSection" /> */}
        </section>

        <section id="expSection" style={sectionStyle}>
          <Experience />
          {/* <ScrollDownBtn to="projects" /> */}
        </section>

        <section id="projects" style={{...sectionStyle,background:"#002244"}}>
          <Projects />
          {/* <ScrollDownBtn to="contact" /> */}
        </section>

        <section id="contact" style={sectionStyle}>
          <Contact />
        </section>
        </>}
        <Footer />
      </motion.div>
    </ChakraProvider>
  );
}

export default App;
