import React,{useState} from "react";
import "./Home.css";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import animationData from "../Images/animations/coder.json"
import ReactTyped from "react-typed";
import gif from "../Images/bot.gif"

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const date= new Date();
  const year= date.getFullYear();
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <motion.div
      className="outer"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
    >
      <div id="homeLeft">
        <p id="hi">
        <ReactTyped strings={[
          "Hi!, I am",
          "नमस्ते, मैं हूँ",
          "Hola, yo soy",
          "Hi Ich bin"
          ]} 
          typeSpeed={100} 
          backSpeed={100} 
          loop />
        </p>

        <h1 id="name">
          <ReactTyped strings={["Aniket Sinha"]} typeSpeed={90} backSpeed={90} loop={false} />
        </h1>

        <h2 id="title">
        Software Engineer
        </h2>

        <h3 id="age">
          {year-2001}
        </h3>
      </div>
      <div id="homeRight">
        <div className="loader">
         <Lottie options={defaultOptions}
              height={500}
              width={500}
              isStopped={isLoading} />
            {/* <img src={gif}/> */}
        </div>
      </div>
    </motion.div>
  );
}
